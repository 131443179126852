import session from "js-cookie";

export default (to, from, next) => {

    const token = session.get('_token')

    if(!token){

        next('/login')
    }
    else
    {
        next()
    }
}
