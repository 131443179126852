<template>
  <master-component>
    <div class="bq-home" v-if="session.get('_nivel') >= 2">
      <v-row class="bq-home-menu">

        <v-col class="dash" cols="12">
          <h2><span>DASH</span><span>BOARD</span></h2>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-lilaz"
              route="/cabecalhos"
              :qtd="totalCabecalhos"
              title="Cabeçalhos"
              icon="mdi-page-layout-header"
              footer="Área de navegação dos cabeçalhos"
          ></link-status>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-verde"
              route="/textos"
              :qtd="totalTextos"
              title="Textos"
              icon="mdi-text-box-outline"
              footer="Área de navegação dos textos"
          ></link-status>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-amarelo"
              route="/questoes"
              :qtd="totalQuestoes"
              title="Questões"
              icon="mdi-help-circle-outline"
              footer="Área de navegação das questões"
          ></link-status>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-azul"
              route="/avaliacoes"
              :qtd="totalAvaliacoes"
              title="Avaliações"
              icon="mdi-chat-outline"
              footer="Área de navegação das avaliações"
          ></link-status>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-vermelho"
              route="/"
              :qtd="totalCreditos"
              title="Créditos"
              icon="mdi-currency-usd"
              footer="Área de navegação dos créditos"
          ></link-status>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <link-status
              bgcolor="bq-card bg-marrom"
              route="/"
              qtd="0"
              title="Alunos"
              icon="mdi-account-multiple-outline"
              footer="Área de navegação dos alunos"
          ></link-status>
        </v-col>

      </v-row>

<!--      <v-card title="Filtros" style="margin-top: 50px; padding-top: 20px; background-color: #eee;">-->
<!--        <v-col cols="12">-->
<!--          <v-autocomplete-->
<!--              label="Ano"-->
<!--              v-model="filters.year"-->
<!--              variant="outlined"-->
<!--              :items="datas.years"-->
<!--              item-title="name"-->
<!--              item-value="id"-->
<!--              @update:modelValue="changeFilterYear"-->
<!--          ></v-autocomplete>-->

<!--          <v-autocomplete-->
<!--              label="Mes"-->
<!--              v-model="filters.month"-->
<!--              variant="outlined"-->
<!--              :items="datas.months"-->
<!--              item-title="name"-->
<!--              item-value="id"-->
<!--              @update:modelValue="changeFilterMonth"-->
<!--          ></v-autocomplete>-->

<!--          <v-autocomplete-->
<!--              label="Dia"-->
<!--              v-model="filters.day"-->
<!--              variant="outlined"-->
<!--              :items="datas.days"-->
<!--              item-title="name"-->
<!--              item-value="id"-->
<!--              @update:modelValue="changeFilterDay"-->
<!--          ></v-autocomplete>-->
<!--        </v-col>-->

<!--        <v-col cols="12">-->
<!--          <v-btn-->
<!--              style="margin-top: 15px; background-color: #f4645f; color: #fff;"-->
<!--              @click="clearFilters"-->
<!--          >-->
<!--            <v-icon icon="mdi-close"></v-icon>-->
<!--            Limpar filtros-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-card>-->

      <!--QUESTÕES-->
      <v-card title="Questões" style="margin-top: 50px; padding-top: 20px; background-color: #eee;">
        <v-col cols="12">
          <v-text-field
              v-model="buscarQuestaoDescricao"
              label="Pesquisar em todo o banco de questões"
              prepend-inner-icon="mdi-magnify"
              single-line
              variant="outlined"
              hide-details
              style="margin-bottom: 20px;"
              @keyup="keyupBuscarQuestao"
          ></v-text-field>
        </v-col>
      </v-card>

      <questao-table-component
          @emitClearFilters="clearDados('Q')"
          @emitUpdateTotais="dataDashboard"
      ></questao-table-component>

      <!--AVALIAÇÕES-->
      <v-card title="Avaliações" style="margin-top: 50px; padding-top: 20px; background-color: #eee;">
        <v-col cols="12">
          <v-text-field
              v-model="buscarAvaliacaoDescricao"
              label="Pesquisar em todo o banco de avaliações"
              prepend-inner-icon="mdi-magnify"
              single-line
              variant="outlined"
              hide-details
              style="margin-bottom: 20px;"
              @keyup="keyupBuscarAvaliacao"
          ></v-text-field>
        </v-col>
      </v-card>

      <avaliacao-table-component
          @emitClearFilters="clearDados('A')"
          @emitUpdateTotais="dataDashboard"
      ></avaliacao-table-component>

      <!--TEXTOS-->
      <v-card title="Textos" style="margin-top: 50px; padding-top: 20px; background-color: #eee;">
        <v-col cols="12">
          <v-text-field
              v-model="buscarTextoDescricao"
              label="Pesquisar em todo o banco de textos"
              prepend-inner-icon="mdi-magnify"
              single-line
              variant="outlined"
              hide-details
              style="margin-bottom: 20px;"
              @keyup="keyupBuscarTexto"
          ></v-text-field>
        </v-col>
      </v-card>

      <texto-table-component
          @emitClearFilters="clearDados('T')"
          @emitUpdateTotais="dataDashboard"
      ></texto-table-component>
    </div>
  </master-component>
</template>

<script>

import MasterComponent from "@/components/layouts/MasterComponent.vue";
import LinkStatus from "@/components/cards/LinkStatus.vue";
import model from "@/modulos/model";
import session from "js-cookie"
import QuestaoTableComponent from "@/components/home/datatables/QuestaoTableComponent.vue"
import AvaliacaoTableComponent from "@/components/home/datatables/AvaliacaoTableComponent.vue";
import TextoTableComponent from "@/components/home/datatables/TextoTableComponent.vue";

export default {
  components: {
    LinkStatus,
    MasterComponent,
    QuestaoTableComponent,
    AvaliacaoTableComponent,
    TextoTableComponent
  },
  data () {
    return {
      buscarQuestaoDescricao: '',
      buscarAvaliacaoDescricao: '',
      buscarTextoDescricao: '',
      session: session,
      totalCabecalhos: 0,
      totalTextos: 0,
      totalQuestoes: 0,
      totalAvaliacoes: 0,
      totalCreditos: 0,
      filters: {
        year: '',
        month: '',
        day: ''
      },
      datas: {
        years: [],
        months: [],
        days: []
      }
    }
  },
  created() {

    this.dataDashboard()
  },
  methods: {

    dataDashboard(){

      this.$store.commit('setOverlay', true)

      model.apiGet('dashboard').then(response => {

        this.$store.commit('setOverlay', false)

        if(response.error){

          this.$store.commit('setSnackbar', {message: response.error, state: true, color: 'red'})
          this.$router.push('/login')
        }
        else if(response.erro){

          this.$store.commit('setSnackbar', {message: response.erro, state: true, color: 'red'})
          this.$router.push('/login')
        }
        else{

          this.totalCabecalhos = response.data.total_cabecalhos
          this.totalTextos = response.data.total_textos
          this.totalQuestoes = response.data.total_questoes
          this.totalAvaliacoes = response.data.total_avaliacoes
          this.totalCreditos = response.data.total_creditos
          this.datas.years = response.data.years
        }
      })
    },
    changeFilterYear(val){

      this.filters.year = val;

      this.listagem()
    },
    changeFilterMonth(val){

      this.filters.month = val;

      this.listagem()
    },
    changeFilterDay(val){

      this.filters.day = val;

      this.listagem()
    },
    listagem(){

      this.pesquisar('dashboard/filters', this.filters, 'LISTAGEM')
    },
    keyupBuscarQuestao(val){

      this.pesquisar('dashboard/questao/busca', {param: val.target.value}, 'QUESTAO' , 'setListagemQuestoes')
    },
    keyupBuscarAvaliacao(val){

      this.pesquisar('dashboard/avaliacao/busca', {param: val.target.value}, 'AVALIACAO', 'setListagemAvaliacoes')
    },
    keyupBuscarTexto(val){

      this.pesquisar('dashboard/texto/busca', {param: val.target.value}, 'TEXTO', 'setListagemTextos')
    },
    pesquisar(route, data, tipo, listagem = ''){

      this.$store.commit('setOverlay', true)

      if(tipo !== 'LISTAGEM'){

        this.clearFilters()
      }

      model.apiPost(route, data).then(response => {

        this.$store.commit('setOverlay', false)

        if(response.error){

          this.$store.commit('setSnackbar', {message: response.error, state: true, color: 'red'})
          this.$router.push('/login')
        }
        else if(response.erro){

          this.$store.commit('setSnackbar', {message: response.erro, state: true, color: 'red'})
          this.$router.push('/login')
        }
        else{

          if(tipo === 'LISTAGEM'){

            this.datas.months = response.months
            this.datas.days = response.days

            this.$store.commit('setListagemQuestoes', response.listagem)
            this.$store.commit('setListagemAvaliacoes', response.listagem_avaliacao)
            this.$store.commit('setListagemTextos', response.listagem_texto)
          }

          this.$store.commit(listagem, response.listagem)
        }
      })
    },
    clearFilters(){

      this.filters = {year: '', month: '', day: ''}
    },
    clearDados(tipo){

      this.filters = {year: '', month: '', day: ''}

      if(tipo === 'Q'){

        this.$store.commit('setListagemQuestoes', [])
        this.buscarQuestaoDescricao = ''
      }
      else if(tipo === 'A'){

        this.$store.commit('setListagemAvaliacoes', [])
        this.buscarAvaliacaoDescricao = ''
      }
      else if(tipo === 'T'){

        this.$store.commit('setListagemTextos', [])
        this.buscarTextoDescricao = ''
      }
    }
  }
}
</script>

<style scoped>

.dash h2{
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 10px 20px;
}
.dash h2 span:nth-child(1){

  color: #7d93b9;
}
.dash h2 span:nth-child(2){

  color: #e4e8ea;
}
.bg-verde{
  background-color: #36ba9b;
}
.bg-amarelo{
  background-color: #f8a500;
}
.bg-azul{
  background-color: #0190ce;
}
.bg-vermelho{
  background-color: #f4645f;
}
.bg-lilaz{
  background-color: #d600e5;
}
.bg-marrom{
  background-color: #bd7e7e;
}
.bq-home-menu{
  margin-top: 35px;
}
.bq-card{
  padding: 0;
  height: 150px;
  border-radius: 7px;
}
.bq-card a{
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: #fff;
}
.bq-card a .q-cima{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
}
.bq-card a .q-baixo{
  background-color: rgba(0, 0, 0, 0.1);
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 0 0 7px 7px;
}
.bq-card a .q-cima .q-cima-direita{
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 15px;
}
.bq-card a .q-cima .q-cima-direita span:nth-child(1){
  font-size: 25pt;
  margin-right: 10px;
}
.bq-card a .q-cima .q-cima-esquerda{
  width: 30%;
  font-size: 25pt;
  color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
