<template>
    <!--DIALOG DELETE-->
    <v-dialog
        v-model="$store.state.dialogConfirmCopyQuestao"
        persistent
        width="1024"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Cópia</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <p><u>{{ $store.state.questao.descricao.replace(/<[^>]*>?/gm, '') }}</u></p>
                <br>
                <p><v-icon icon="mdi-information-outline" color="blue" /> Deseja copiar essa questão?</p>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red-darken-1" variant="text" @click="notCopy">
            Não
          </v-btn>
          <v-btn color="green-darken-1" variant="text" @click="yesCopy">
            Sim
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>

<script>

import model from "@/modulos/model";

export default {

  methods: {

    yesCopy(){

      this.$store.commit('setOverlay', true)

      model.apiPost('dashboard/questao/copiar', {id: this.$store.state.editedIndex}).then(response => {

        this.$store.commit('setOverlay', false)

        if(response.error){

          this.$store.commit('setSnackbar', {message: response.error, state: true, color: 'red'})
          this.$router.push('/login')
        }
        else if(response.erro){

          this.$store.commit('setSnackbar', {message: response.erro, state: true, color: 'red'})
          this.$store.commit('setDialogConfirmCopyQuestao', false)
        }
        else{

          this.$emit('clearFilters')
          this.$emit('updateTotais')
          this.$store.commit('setListagemQuestoes', [])
          this.$store.commit('setDialogConfirmCopyQuestao', false)
          this.$store.commit('setSnackbar', {message: response.sucesso, state: true, color: 'green'})
        }
      })
    },
    notCopy(){

      this.$store.commit('setEditedIndex', 0)
      this.$store.commit('setDialogConfirmCopyQuestao', false)
    }
  }
}
</script>


<style scoped>

</style>
