<template>
  <v-card style="margin: 20px 0;">
    <template v-slot:text>
      <v-text-field
          v-model="search"
          label="Pesquisar na tabela de textos"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="outlined"
          hide-details
      ></v-text-field>

      <v-btn
          style="margin-top: 15px; background-color: #f4645f; color: #fff;"
          @click="clearFilters"
      >
        <v-icon icon="mdi-close"></v-icon>
        Limpar dados
      </v-btn>
    </template>

    <v-data-table
        :headers="headers"
        :items="$store.state.listagemTextos"
        :search="search"
        :items-per-page="5"
    >
      <template v-slot:[`item.actions`]="{item}">
        <v-icon small class="mr-2" @click="copiarTexto(item)" title="Copiar texto">mdi-content-copy</v-icon>
      </template>
    </v-data-table>
  </v-card>

  <dialog-confirm-copy
      @clearFilters="clearFilters"
      @updateTotais="$emit('emitUpdateTotais')"
  ></dialog-confirm-copy>

</template>

<script>

import DialogConfirmCopy from "@/components/home/datatables/confirm/DialogConfirmCopyTexto.vue";

export default {
  components: {
    DialogConfirmCopy
  },
  data () {
    return {
      search: '',
      headers: [
        {title: 'Título', sortable: false, key: 'titulo', align: 'start', width: '70%'},
        {title: 'Ações', value: 'actions', sortable: false, align: 'center', width: 150}
      ]
    }
  },
  created(){

    this.$store.commit('setListagemTextos', [])
  },
  methods: {

    copiarTexto(item){

      this.$store.commit('setEditedIndex', item.id)
      this.$store.commit('setTexto', {titulo: item.titulo})
      this.$store.commit('setDialogConfirmCopyTexto', true)
    },
    clearFilters(){

      this.$emit('emitClearFilters')
    }
  }
}
</script>

<style scoped>

</style>
