<template>
<div class="total">
  <div class="esquerda">
    <img :src="require('../assets/logo-padrao.png')"/>
    <h1>Login</h1>
    <div class="inputs">
      <form @submit.prevent="login">

        <div class="form-group">
          <label>
            <span><v-icon small>mdi-email</v-icon></span>
            <span>Email:</span>
          </label>
          <input type="text" v-model="editedItem.email">
        </div>

        <div class="form-group">
          <label>
            <span><v-icon small>mdi-lock-open</v-icon></span>
            <span>Senha:</span>
          </label>
          <input type="password" v-model="editedItem.password">
        </div>

        <button type="submit">
          <div>
            <span><v-icon small>mdi-login</v-icon></span>
            <span>Entrar</span>
          </div>
        </button>

      </form>
    </div>
    <div class="creditos">
      <p>Desenvolvido por Devalx Web Solutions<br>&copy; {{ dataAtual }} - Todos os diretos reservados</p>
      <p><a :href="whatsapp" target="blank">Precisa de Suporte?</a></p>
    </div>
  </div>
  <div class="direita">
    <img :src="require('../assets/logo-second.png')">
  </div>

  <v-snackbar v-model="$store.state.snackbar.state" :color="$store.state.snackbar.color">
    {{ $store.state.snackbar.message }}
    <template v-slot:actions>
      <v-btn
          color="white"
          variant="text"
          @click="$store.commit('setSnackbar', {message: '', state: false, color: ''})"
      >
        ok
      </v-btn>
    </template>
  </v-snackbar>

  <v-overlay
      :model-value="$store.state.overlay"
      class="align-center justify-center"
  >
    <v-progress-circular
        color="white"
        indeterminate
        size="64"
    ></v-progress-circular>
  </v-overlay>

</div>
</template>

<script>
import axios from "axios";
import session from "js-cookie";

export default {

  data: () => ({
    url: 'https://www.api.schools.devalx.com.br/public/api/login',
    whatsapp: "https://api.whatsapp.com/send?phone=5573991025508",
    editedItem: {
      email: '',
      password: ''
    },
    dataAtual: (new Date()).getFullYear(),
    token: false
  }),
  created() {

    if(session.get('_token')){

      this.$router.push("/")
    }
  },
  methods: {

    login(){

      this.$store.commit('setOverlay', true)

      axios.post(this.url, {email: this.editedItem.email, password: this.editedItem.password}).then(response => {

        this.$store.commit('setOverlay', false)

        if(!response.data.erro){

          this.token = response.data.token.original.token_type + " " + response.data.token.original.access_token

          session.set('_token', this.token)
          session.set('_usuario_id', response.data.user.id)
          session.set('_usuario_nome', response.data.user.name)
          session.set('_nivel', response.data.user.level)

          this.$store.commit('setSnackbar', {message: response.data.sucesso, state: true, color: 'green'})

          //this.$router.push("/")
          this.$router.go(0)
        }
        else if(response.data.error){

          this.$store.commit('setSnackbar', {message: response.data.error, state: true, color: 'red'})
        }
        else {

          this.$store.commit('setSnackbar', {message: response.data.erro, state: true, color: 'red'})
        }

        return response.data.message

      }).catch(error => {

        this.$store.commit('setOverlay', false)
        this.$store.commit('setSnackbar', {message: error, state: true, color: 'red'})
      })
    }
  }
}
</script>

<style scoped>

.total {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.esquerda {
  width: 50%;
  height: 100%;
  background-color: #282f3a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.esquerda img{
  width: 200px;
}
.esquerda h1{
  margin-bottom: 30px;
}
.esquerda .inputs {
  width: 100%;
}
.esquerda .inputs form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-group{
  width: 75%;
}
.form-group label span:nth-child(1){
  font-size: 13px;
  margin-right: 4px;
}
.form-group label{
  display: flex;
  align-items: center;
}
.esquerda .inputs input {
  background-color: #fff;
  color: #282f3a;
  width: 100%;
  padding: 10px 8px;
  outline: none;
  border-radius: 2px;
  margin-bottom: 30px;
}
.esquerda .inputs button{
  width: 75%;
  padding: 10px 15px;
  border-radius: 2px;
  margin-bottom: 30px;
  background-image: linear-gradient(45deg, #b74a47, #f4645f);
}
.esquerda .inputs button div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.esquerda .inputs button div span:nth-child(1){
  font-size: 13px;
  margin-right: 4px;
}

.esquerda .inputs button:hover{
  background-image: linear-gradient(-45deg, #b74a47, #f4645f);
}
.esquerda .creditos p {
  text-align: center;
}
.creditos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  font-size: 12px;
}
.esquerda .creditos p:nth-child(1) {
  text-align: center;
  color: #fff;
  margin-bottom: 15px;
}
.esquerda .creditos p:nth-child(2) a {
  text-align: center;
  color: #36ba9b;
  text-decoration: none;
}
.direita {
  width: 50%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.direita img{
  width: 60%;
}

@media only screen and (max-width: 800px) {
  .direita{
    display: none;
  }
  .esquerda{
    width: 100%;
  }
}

@media only screen and (min-width: 1150px) {
  .direita{
    display: flex;
    width: 60%;
  }
  .esquerda{
    width: 40%;
  }
}
</style>
