<template>
  <v-card style="margin-top: 20px;">
    <template v-slot:text>
      <v-text-field
          v-model="search"
          label="Pesquisar na tabela de avaliações"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="outlined"
          hide-details
      ></v-text-field>

      <v-btn
          style="margin-top: 15px; background-color: #f4645f; color: #fff;"
          @click="clearFilters"
      >
        <v-icon icon="mdi-close"></v-icon>
        Limpar dados
      </v-btn>
    </template>

    <v-data-table
        :headers="headers"
        :items="$store.state.listagemAvaliacoes"
        :search="search"
        :items-per-page="5"
    >
      <template v-slot:[`item.actions`]="{item}">
        <v-icon small class="mr-2" @click="copiarAvaliacao(item)" title="Copiar avaliação">mdi-content-copy</v-icon>
      </template>
    </v-data-table>
  </v-card>

  <dialog-confirm-copy
      @clearFilters="clearFilters"
      @updateTotais="$emit('emitUpdateTotais')"
  ></dialog-confirm-copy>

</template>

<script>

import DialogConfirmCopy from "@/components/home/datatables/confirm/DialogConfirmCopyAvaliacao.vue";

export default {
  components: {
    DialogConfirmCopy
  },
  data () {
    return {
      search: '',
      headers: [
        {title: 'Titulo', sortable: false, key: 'titulo', align: 'start', width: '50%'},
        {title: 'Conteúdo', sortable: false, key: 'conteudo', align: 'start'},
        {title: 'Série', sortable: false, key: 'serie', align: 'start'},
        {title: 'Disciplina', sortable: false, key: 'disciplina', align: 'start'},
        {title: 'Data', sortable: false, key: 'data', align: 'start'},
        {title: 'Ações', value: 'actions', sortable: false, align: 'center', width: 150}
      ]
    }
  },
  created(){

    this.$store.commit('setListagemQuestoes', [])
  },
  methods: {

    copiarAvaliacao(item){

      this.$store.commit('setEditedIndex', item.id)
      this.$store.commit('setAvaliacao', {titulo: item.titulo})
      this.$store.commit('setDialogConfirmCopyAvaliacao', true)
    },
    clearFilters(){

      this.$emit('emitClearFilters')
    }
  }
}
</script>

<style scoped>

</style>
