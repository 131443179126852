import { createStore } from 'vuex'

export default createStore({
  state: {
    dialog: false,
    overlay: false,
    snackbar: {
      message: '',
      state: false,
      color: ''
    },
    headers: [],
    listagem: [],
    listagemQuestoes: [],
    listagemTextos: [],
    listagemAvaliacoes: [],
    levels: [
        {id: 1, name: 'Aluno'},
        {id: 2, name: 'Professor'},
        {id: 3, name: 'Admin'}
    ],
    alternativas: [],
    editedIndex: 0,
    dialogDelete: false,
    user: {
      name: '',
      email: '',
      password: '',
      level: '',
      credit: ''
    },
    serie: {name: ''},
    disciplina: {name: ''},
    cabecalho: {
      unen: '',
      slogan_unen: '',
      img_unen: '',
      img_semec: '',
      img_unen_prop: '',
      img_semec_prop: '',
    },
    texto: {
      titulo: '',
      conteudo: '',
      img_prop: '',
      img_posi: '',
      imagem: ''
    },
    questao: {
      serie_id: '',
      disciplina_id: '',
      texto_id: '',
      tipo: '',
      numero_linhas: '',
      pontos: '',
      descricao: '',
      img_prop: '',
      img_posi: '',
      imagem: ''
    },
    questaoTipo: [
      {id: 1, name: 'Objetiva'},
      {id: 2, name: 'Subjetiva'},
      {id: 3, name: 'Verdadeiro ou Falso'},
      {id: 4, name: 'Relacional'},
      {id: 5, name: 'Combo'}
    ],
    cabecalhos: [],
    series: [],
    disciplinas: [],
    textos: [],
    dialogAddAlternativa: false,
    alternativa: {
      id: 0,
      questao_id: '',
      tipo: '',
      certa: '',
      numero_linhas: '',
      opcao: '',
      coluna_1: '',
      coluna_2: '',
      par: '',
      lado: ''
    },
    yesNot: [{id: 1, name: 'Sim'}, {id: 2, name: 'Não'}],
    letra: [
        {id: 'A', name: 'A'},
        {id: 'B', name: 'B'},
        {id: 'C', name: 'C'},
        {id: 'D', name: 'D'},
        {id: 'E', name: 'E'}
    ],
    dialogVerQuestaoAlternativa: false,
    dialogQuestaoAlternativaDelete: false,
    avaliacao: {
      id: '',
      serie_id: '',
      disciplina_id: '',
      cabecalho_id: '',
      data: '',
      titulo: '',
      conteudo: '',
      citacao: ''
    },
    dialogAddQuestao: false,
    questoes: [],
    avaliacaoQuestoes: [],
    dialogConfirmCopyQuestao: false,
    dialogConfirmCopyAvaliacao: false,
    dialogConfirmCopyTexto: false,
    proporcoes: [{id: 1, name: 'Quadrada'}, {id: 2, name: 'Retangular'}],
    posicoes: [{id: 1, name: 'Acima'}, {id: 2, name: 'Ao lado'}]
  },
  getters: {
  },
  mutations: {
    setDialog(state, data){

      state.dialog = data
    },
    setOverlay(state, data){

      state.overlay = data
    },
    setSnackbar(state, data){

      state.snackbar = data
    },
    setHeaders(state, data){

      state.headers = data
    },
    setDesserts(state, data){

      state.desserts = data
    },
    setAlternativas(state, data){

      state.alternativas = data
    },
    setEditedIndex(state, data){

      state.editedIndex = data
    },
    setDialogDelete(state, data){

      state.dialogDelete = data
    },
    setUser(state, data){

      state.user = data
    },
    setSerie(state, data){

      state.serie = data
    },
    setDisciplina(state, data){

      state.disciplina = data
    },
    setCabecalho(state, data){

      state.cabecalho = data
    },
    setTexto(state, data){

      state.texto = data
    },
    setQuestao(state, data){

      state.questao = data
    },
    setCabecalhos(state, data){

      state.cabecalhos = data
    },
    setSeries(state, data){

      state.series = data
    },
    setDisciplinas(state, data){

      state.disciplinas = data
    },
    setTextos(state, data){

      state.textos = data
    },
    setDialogAddAlternativa(state, data){

      state.dialogAddAlternativa = data
    },
    setAlternativa(state, data){

      state.alternativa = data
    },
    setDialogVerQuestaoAlternativa(state, data){

      state.dialogVerQuestaoAlternativa = data
    },
    setDialogQuestaoAlternativaDelete(state, data){

      state.dialogQuestaoAlternativaDelete = data
    },
    setAvaliacao(state, data){

      state.avaliacao = data
    },
    setListagem(state, data){

      state.listagem = data
    },
    setListagemQuestoes(state, data){

      state.listagemQuestoes = data
    },
    setListagemTextos(state, data){

      state.listagemTextos = data
    },
    setListagemAvaliacoes(state, data){

      state.listagemAvaliacoes = data
    },
    setDialogAddQuestao(state, data){

      state.dialogAddQuestao = data
    },
    setQuestoes(state, data){

      state.questoes = data
    },
    setAvaliacaoQuestoes(state, data){

      state.avaliacaoQuestoes = data
    },
    setDialogConfirmCopyQuestao(state, data){

      state.dialogConfirmCopyQuestao = data
    },
    setDialogConfirmCopyAvaliacao(state, data){

      state.dialogConfirmCopyAvaliacao = data
    },
    setDialogConfirmCopyTexto(state, data){

      state.dialogConfirmCopyTexto = data
    }
  },
  actions: {
  },
  modules: {
  }
})
