<template>
  <div :class="bgcolor">
    <router-link :to="route">
      <div class="q-cima">
        <div class="q-cima-direita">
          <span>{{ qtd }}</span>
          <span>{{ title }}</span>
        </div>
        <div class="q-cima-esquerda">
          <span class="icon"><v-icon :icon="icon" /></span>
        </div>
      </div>
      <div class="q-baixo">
        {{ footer }}
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: [
      'bgcolor',
      'route',
      'qtd',
      'title',
      'icon',
      'footer',
  ],
}
</script>

<style scoped>
.dash h2{
  border: solid 1px #ddd;
  border-radius: 3px;
  padding: 10px 20px;
}
.dash h2 span:nth-child(1){

  color: #7d93b9;
}
.dash h2 span:nth-child(2){

  color: #e4e8ea;
}
.bg-verde{
  background-color: #36ba9b;
}
.bg-amarelo{
  background-color: #f8a500;
}
.bg-azul{
  background-color: #0190ce;
}
.bg-vermelho{
  background-color: #f4645f;
}
.bg-lilaz{
  background-color: #d600e5;
}
.bg-marrom{
  background-color: #bd7e7e;
}
.bq-home-menu{
  margin-top: 35px;
}
.bq-card{
  padding: 0;
  height: 150px;
  border-radius: 7px;
}
.bq-card a{
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  color: #fff;
}
.bq-card a .q-cima{
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
}
.bq-card a .q-baixo{
  background-color: rgba(0, 0, 0, 0.1);
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 0 0 7px 7px;
}
.bq-card a .q-cima .q-cima-direita{
  width: 70%;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 15px;
}
.bq-card a .q-cima .q-cima-direita span:nth-child(1){
  font-size: 25pt;
  margin-right: 10px;
}
.bq-card a .q-cima .q-cima-esquerda{
  width: 30%;
  font-size: 25pt;
  color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
