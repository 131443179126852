<template>
  <div class="main">
    <div class="header">
      <span class="menu" @click="changeMenu"><v-icon icon="mdi-menu" /></span>
      <span>{{ session.get('_usuario_nome') }}</span>
    </div>
    <div class="navigation">
      <ul>
        <li id="home" class="list" @click="activeLink('home')">
          <router-link to="/">
            <span class="icon"><v-icon icon="mdi-home-outline" /></span>
            <span class="title">Home</span>
          </router-link>
        </li>
        <li id="user" class="list" @click="activeLink('user')">
          <router-link to="/users">
            <span class="icon"><v-icon icon="mdi-account-multiple-outline" /></span>
            <span class="title">Usuário</span>
          </router-link>
        </li>
        <li v-if="session.get('_nivel') >= 3" id="series" class="list" @click="activeLink('series')">
          <router-link to="/series">
            <span class="icon"><v-icon icon="mdi-numeric" /></span>
            <span class="title">Séries</span>
          </router-link>
        </li>
        <li v-if="session.get('_nivel') >= 3" id="disciplinas" class="list" @click="activeLink('disciplinas')">
          <router-link to="/disciplinas">
            <span class="icon"><v-icon icon="mdi-text-box-outline" /></span>
            <span class="title">Discipinas</span>
          </router-link>
        </li>
        <li id="questoes" class="list" @click="activeLink('questoes')" style="display: none">
          <router-link to="/questoes">
            <span class="icon"><v-icon icon="mdi-help-circle-outline" /></span>
            <span class="title">Questões</span>
          </router-link>
        </li>
        <li id="avaliacoes" class="list" @click="activeLink('avaliacoes')" style="display: none">
          <router-link to="/avaliacoes">
            <span class="icon"><v-icon icon="mdi-chat-outline" /></span>
            <span class="title">Avaliações</span>
          </router-link>
        </li>
        <li id="textos" class="list" @click="activeLink('textos')" style="display: none">
          <router-link to="/textos">
            <span class="icon"><v-icon icon="mdi-text-box-outline" /></span>
            <span class="title">Textos</span>
          </router-link>
        </li>
        <li id="cabecalhos" class="list" @click="activeLink('cabecalhos')" style="display: none">
          <router-link to="/cabecalhos">
            <span class="icon"><v-icon icon="mdi-text-box-outline" /></span>
            <span class="title">Textos</span>
          </router-link>
        </li>
        <li id="out" class="list" @click="changeLogout">
          <a href="javascript:void(0)" style="color: #f4645f;">
            <span class="icon"><v-icon icon="mdi-logout" /></span>
            <span class="title">Sair</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="conteudo">
      <div class="conteudo-child">
        <slot></slot>
      </div>
    </div>

    <v-snackbar v-model="$store.state.snackbar.state" :color="$store.state.snackbar.color">
      {{ $store.state.snackbar.message }}
      <template v-slot:actions>
        <v-btn
            color="white"
            variant="text"
            @click="$store.commit('setSnackbar', {message: '', state: false, color: ''})"
        >
          ok
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay
        :model-value="$store.state.overlay"
        class="align-center justify-center"
    >
      <v-progress-circular
          color="white"
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>

import model from "@/modulos/model";
import session from "js-cookie";

export default ({

  data: () => ({
    titulo: 'Home Page',
    menu: false,
    session: session,
    user: session.get('_usuario_nome'),
    level: session.get('_nivel'),
  }),
  mounted() {

    document.title = 'Banco de questões'
    let routeName = this.$route.name
    this.activeLink(routeName)
  },
  methods: {
    changeMenu(){

      let navigation = document.querySelector('.navigation');

      if(this.menu){
        this.menu = false
      }
      else{
        this.menu = true
      }

      if(this.menu){
        navigation.classList.add("expande")
      }
      else{
        navigation.classList.remove("expande")
      }
    },
    activeLink(item){

      let list = document.querySelectorAll('.list')

      list.forEach((item) => item.classList.remove("active"))

      let link = document.querySelector('#' + item);
      link.classList.add("active")

      if(this.menu){
        this.changeMenu()
      }
    },
    changeLogout() {

      this.$store.commit('setOverlay', true)

      model.apiLogout().then(response => {

        this.$store.commit('setOverlay', false)

        if(response.sucesso){

          this.$store.commit('setSnackbar', {message: response.sucesso, state: true, color: 'green'})
        }
        else{

          this.$store.commit('setSnackbar', {message: response.erro, state: true, color: 'red'})
        }

        this.$router.push("/login")
      })
    }
  }
});
</script>

<style scoped>

.main{
  position: relative;
  height: 100vh;
}
.header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 10%;
  background-color: #282f3a;
}
.header span:nth-child(2){
  color: #fff;
  margin-right: 30px;
}
.header{
  display: flex;
  align-items: center;
}
.header .menu{
  margin-left: 25px;
  color: #fff;
  cursor: pointer;
}
.navigation{
  position: absolute;
  height: 90%;
  background-color: #282f3a;
  width: 70px;
  box-shadow: 10px 0 0 #f4645f;
  border-left: 10px solid #282f3a;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 1;
}
.navigation.expande{
  width: 300px;
}
.navigation ul li .menu{
  color: #fff;
  font-size: 23px;
  cursor: pointer;
}
.navigation ul li .menu:hover{
  color: #f4645f;
}
.navigation ul{
  top: 0;
  left: 0;
  width: 100%;
  list-style: none;
  padding-left: 5px;
  padding-top: 40px;
}
.navigation ul li{
  width: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.navigation ul li a{
  position: relative;
  display: flex;
  width: 100%;
  text-decoration: none;
  color: #fff;
}
.navigation ul li a .icon{
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
}
.navigation ul li a .icon i{
  position: relative;
  font-size: 1.5rem;
  z-index: 1;
}
.navigation ul li a .title{
  position: relative;
  display: block;
  padding-left: 10px;
  height: 60px;
  line-height: 62px;
  white-space: nowrap;
}
.navigation ul li.active{
  background-color: #f4645f;
}
.navigation ul li.active a::before{
  content: '';
  position: absolute;
  top: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  background: #282f3a;
  border-radius: 50%;
  box-shadow: 15px 15px 0 #f4645f;
}

.navigation ul li.active a::after{
  content: '';
  position: absolute;
  bottom: -30px;
  right: 0;
  width: 30px;
  height: 30px;
  background: #282f3a;
  border-radius: 50%;
  box-shadow: 15px -15px 0 #f4645f;
}

.conteudo{
  width: 100%;
  height: 85%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.conteudo .conteudo-child{
  width: 80%;
}

@media only screen and (max-width: 850px) {
  .navigation{
    width: 0;
    border: 0;
  }
  .conteudo .conteudo-child{
    width: 95%;
  }
}
</style>
