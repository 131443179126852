import session from "js-cookie";

export default (to, from, next) => {

    const token = session.get('_token')
    const level = session.get('_level')

    if(!token || level < 3){

        next('/login')
    }
    else {

        next()
    }
}
