//Middlewares
import guard from '@/middleware/auth'
import semec from "@/middleware/semec";
import admin from "@/middleware/admin";
//Sistema
import {createRouter, createWebHistory} from 'vue-router'
import LoginView from "@/views/LoginView.vue";
import HomeView from '../views/HomeView.vue'

const routes = [
  {path: '/login', name: 'login', component: LoginView},
  {path: '/', name: 'home', component: HomeView, beforeEnter: guard},
  {path: '/users', name: 'user', component: () => import('../views/UserView.vue'), beforeEnter: guard},
  {path: '/series', name: 'series', component: () => import('../views/SerieView.vue'), beforeEnter: admin},
  {path: '/disciplinas', name: 'disciplinas', component: () => import('../views/DisciplinaView.vue'), beforeEnter: admin},
  {path: '/cabecalhos', name: 'cabecalhos', component: () => import('../views/CabecalhoView.vue'), beforeEnter: semec},
  {path: '/textos', name: 'textos', component: () => import('../views/TextoView.vue'), beforeEnter: semec},
  {path: '/questoes', name: 'questoes', component: () => import('../views/QuestaoView.vue'), beforeEnter: semec},
  {path: '/avaliacoes', name: 'avaliacoes', component: () => import('../views/AvaliacaoView.vue'), beforeEnter: semec},
  {path: '/out', name: 'out', component: () => import('../views/SerieView.vue'), beforeEnter: guard},
  {path: '/avaliacoes/ver/:id', name: 'avaliacoes.ver', component: () => import('../views/relatorios/VerAvaliacao.vue'), beforeEnter: guard},
  {path: '/:pathMatch(.*)*', name: 'home', component: HomeView, beforeEnter: guard}
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
