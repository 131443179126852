import axios from 'axios'
import session from 'js-cookie'

axios.defaults.baseURL = 'https://api.schools.devalx.com.br/public/api'
axios.defaults.headers.common['Authorization'] = session.get('_token')
axios.defaults.headers.common['accept'] = 'application/json'

export default {

    apiLogout(){

        session.remove('_token')
        session.remove('_level')

        return axios.post('logout').then(response => {

            return response.data

        }).catch(error => {

            return error.response.data
        })
    },

    apiGet(uri, id = false)
    {
        if(id)
        {
            uri = uri + '/' + id
        }

        return axios.get(uri).then((response) => {

            return response

        }).catch(error => {

            if(error.response.data.error){

                session.remove('_token')
                session.remove('_level')
            }

            return error.response.data
        })
    },

    apiPost(uri, data){

        return axios.post(uri, data).then((response) => {

            return response.data

        }).catch(error => {

            if(error.response.data.error){

                session.remove('_token')
                session.remove('_level')
            }

            return error.response.data
        })
    },

    apiPut(uri, id, data){

        data._method = 'PUT'

        uri = uri + '/' + id

        return axios.put(uri, data).then((response) => {

            return response

        }).catch(error => {

            if(error.response.data.error){

                session.remove('_token')
                session.remove('_level')
            }

            return error.response.data
        })
    },

    apiDel(uri, id){

        uri = uri + '/' + id

        return axios.delete(uri, {_method: 'DELETE'}).then((response) => {

            return response

        }).catch(error => {

            if(error.response.data.error){

                session.remove('_token')
                session.remove('_level')
            }

            return error.response.data
        })
    }
}
